<template>
    <div class="tecnologias">
        <h1>Habilidades e Tecnologias</h1>
        <div>
            <img src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/html5/html5-original-wordmark.svg" alt="HTML5 Icon">
            <img src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/css3/css3-original-wordmark.svg" alt="">
            <img class="img2" src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/javascript/javascript-original.svg" alt="">
            <img class="img3" src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/git/git-original.svg" alt="">
            <img src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/vuejs/vuejs-original.svg" alt="">
            <img src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/php/php-original.svg" alt="">
            <img src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/mysql/mysql-original-wordmark.svg" alt="">
            <img src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/python/python-original.svg" alt="">
            <img src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/c/c-original.svg" alt="">
        </div>
    </div>
</template>

<script>
export default {
    name: 'habilidades',
}
</script>

<style scoped>
.tecnologias {
    background-color: black;
    color: white;
    border: 5px solid #58cbf2;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin: 50px;
}

h1 {
    color: white;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
}

img {
    width: 50px; 
    height: auto; 
}

.img2 {
    width: 40px;
    height: 40px;
}

.img3 {
    height: 40px;
}

/* Ajuste para dispositivos móveis */
@media screen and (max-width: 768px) {
    h1 {
        font-size: 16px; /* Reduz o tamanho do título para dispositivos móveis */
    }
}
</style>
