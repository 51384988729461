<template>
  <div class="principal">
    <h1>Conheça a minha jornada</h1>
    <div class="carousel-container">
      <div class="carousel" :style="{ transform: `translateX(-${currentSlide * 100}%)` }">
        <div class="carousel-item">
          <img class="quadrinho" src="@/assets/quadrinho1.png" alt="quadrinho">
        </div>
        <div class="carousel-item">
          <img class="quadrinho" src="@/assets/quadrinho2.png" alt="quadrinho">
        </div>
        <div class="carousel-item">
          <img class="quadrinho" src="@/assets/quadrinho3.png" alt="quadrinho">
        </div>
        <div class="carousel-item">
          <img class="quadrinho" src="@/assets/quadrinho4.png" alt="quadrinho">
        </div>
      </div>
      <button class="carousel-button prev" @click="prevSlide">❮</button>
      <button class="carousel-button next" @click="nextSlide">❯</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'historia',
  data() {
    return {
      currentSlide: 0,
    };
  },
  methods: {
    nextSlide() {
      this.currentSlide = (this.currentSlide + 1) % 4;
    },
    prevSlide() {
      this.currentSlide = (this.currentSlide + 4 - 1) % 4;
    },
  },
};
</script>

<style scoped>
.quadrinho {
  width: 100%;
  height: auto;
  max-width: 600px;
  max-height: 600px;
}

.principal {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

h1 {
  color: white;
  background-color: black;
  border: solid #58cbf2;
  font-family: 'Roboto', sans-serif;
  text-align: center;
}

.carousel-container {
  position: relative;
  width: 100%;
  max-width: 600px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.carousel-item {
  min-width: 100%;
  box-sizing: border-box;
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  padding: 10px;
  cursor: pointer;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

@media screen and (max-width: 768px) {
  .quadrinho {
    max-width: 600px;
    max-height: 600px;
  }

  .carousel-container {
    max-width: 600px;
  }
  h1{
      font-size: 20px;
      margin-top: 5px;
  }
  .principal {
    margin-top: 10px;
  }
}
</style>
