<template>
  <div>
    <cabecalho />
    <section id="inicio">
      <apresentacao />
    </section>
    <section id="habilidades">
      <habilidades />
    </section>
    <section id="projetos">
      <siteseapi />
    </section>
    <section id="historia">
      <historia />
    </section>
    <videoap />
    <section>
    <rodape /> 
  </section>
  </div>
</template>

<script>
import cabecalho from './components/cabecalho.vue';
import apresentacao from './components/apresentacao.vue';
import habilidades from './components/habilidades.vue';
import siteseapi from './components/siteseapi.vue';
import rodape from './components/rodape.vue';
import historia from './components/historia.vue';
import Videoap from './components/videoap.vue';

export default {
  name: 'App',
  components: {
    cabecalho,
    apresentacao,
    habilidades,
    siteseapi,
    rodape,
    historia,
    Videoap
  }
};
</script>

<style>
/* Adicione estilos personalizados aqui, se necessário */
</style>
