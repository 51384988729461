import { createApp } from 'vue';
import App from './App.vue';
import { createRouter, createWebHistory } from 'vue-router';
import 'bootstrap/dist/js/bootstrap.js';

import Historia from './components/historia.vue';
import Siteseapi from './components/siteseapi.vue';

// Defina as rotas
const routes = [
  {
    path: '/',
    name: 'Inicio',
    component: {
      template: '<div>Bem-vindo ao Início</div>'
    }
  },
  {
    path: '/historia',
    name: 'Historia',
    component: Historia
  },
  {
    path: '/projetos',
    name: 'Projetos',
    component: Siteseapi
  }
];

// Crie o roteador
const router = createRouter({
  history: createWebHistory(),
  routes
});

// Crie a aplicação Vue
const app = createApp(App);

// Use o roteador
app.use(router);

// Monte a aplicação
app.mount('#app');
