<template>
  <div>
    <main>
      <div class="conteudoperfil">
        <div>
          <h1>
            <span ref="typingText">{{ visibleText }}</span>
          </h1>
          <h2> Olá, eu sou Guilherme Mendes</h2>
          <p>Desenvolvedor Full Stack com expertise em Front-End e Back-End | <br>
            <strong>Estudante de Engenharia de Software</strong> (Bacharelado) <br>
            com foco em aprimorar habilidades técnicas
          </p>
          <!-- Link de Download -->
          <a href="/CV.pdf" download="Guilherme_Mendes_CV.pdf" class="download-button">Download Currículo</a>
        </div>
        <img class="perfil" :src="require('@/assets/gui.png')" alt="Guilherme Mendes" @mouseover="moveImage" @mouseout="resetImage">
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: 'apresentacao',
  data() {
    return {
      text: 'Bem-vindo ao meu repositório!',
      visibleText: '',
      index: 0
    };
  },
  methods: {
    moveImage() {
      const image = document.querySelector('.perfil');
      image.style.transform = 'translateY(-10px)';
    },
    resetImage() {
      const image = document.querySelector('.perfil');
      image.style.transform = 'translateY(0)';
    },
    typeText() {
      if (this.index < this.text.length) {
        this.visibleText += this.text.charAt(this.index);
        this.index++;
        setTimeout(this.typeText, 100); // Ajuste o tempo conforme necessário
      }
    }
  },
  mounted() {
    this.typeText();
  }
};
</script>

<style scoped>
h1 {
  color: white;
  margin-top: 150px;
  margin-left: 50px;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
  white-space: nowrap;
  overflow: hidden;
}

.hidden {
  display: none;
}

h2 {
  color: white;
  font-size: 15px;
  margin-left: 50px;
  font-family: 'Roboto', sans-serif;
}

p {
  color: white;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  margin-left: 50px;
}

.perfil {
  width: 400px;
  margin-top: 70px;
  transition: transform 0.3s;
}

.conteudoperfil {
  display: flex;
  border: 5px solid black;
}

.perfil:hover {
  transform: translateY(-20px);
}

.download-button {
  display: block;
  margin: 8px;
  padding:20px;
  font-size: 16px; /* Ajustado o tamanho da fonte */
  font-family: 'Roboto', sans-serif;
  color:black;
  background-color: #58cbf2;
  width: 150px;
  align-items: center;
  margin-left: 46px;

  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.download-button:hover {
  background-color:black;
  color: white;
}
a{
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  h1 {
    color: white;
    margin-top: 150px;
    margin-left: 15px;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
    white-space: nowrap;
    overflow: hidden;
  }
  p {
    color: white;
    font-family: 'Roboto', sans-serif;
    font-size: 10px;
    margin-left: 15px;
  }
  .perfil {
    width: 200px;
    margin-top: 70px;
    transition: transform 0.3s;
  }
  h2 {
    color: white;
    font-size: 11px;
    margin-left: 15px;
    font-family: 'Roboto', sans-serif;
  }
  .download-button {
    margin-left: 15px;
    font-size: 12px;
    padding: 5px 10px; /* Ajustado o padding */
    width: 110px;
    height: 15px;
   
  }
}
</style>
