<template>
    <div>
        <footer>
            <div class="rodape">
            <img class="imagemgui" :src="require('@/assets/g.png')" alt="Guilherme Mendes">
            <h4>Entre em Contato</h4>
                <p>Email: guilherme.engsw@gmail.com</p>
                <p>Telefone: (21) 9957-11025</p>
                <p>&copy; 2024 Guilherme. Todos os direitos reservados.</p></div>
        </footer>
    </div>
</template>
<script>
export default {
name: 'rodape',
}
</script>
<style scoped>
.rodape{
    background-color: #000;
    color: #fff;
    padding: 10px 0;
    text-align: center;
    font-size: 14px;
   display: flex;
   align-items: center;
   flex-direction: column;
    
    font-family: 'Roboto', sans-serif;
    margin-top: 60px;
}
@media screen and (max-width: 768px) {
    .rodape {
      padding: 10px;
    }
    
    .imagemgui {
      width: 40px;
      height: 40px;
      margin-left: 100px;
      
     
      
    }}
</style>