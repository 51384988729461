<template>
    <div class="video-container">
        <h1>Minha apresentação</h1>
        <iframe src="https://www.youtube.com/embed/ShoIpqNp_Hc?si=5r7XkA6tOwwrUmZg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </div>
</template>

<script>
export default {
    name: 'video'
}
</script>

<style scoped>
.video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 40px;
    font-family: 'Roboto', sans-serif;
    color: white;
    
}
iframe{
    width: 1000px;
    height: 500px;
}

/* Ajuste para dispositivos móveis */
@media screen and (max-width: 768px) {
    .video-container iframe {
        width: 100%; /* Define a largura como 100% */
        height: 200px; /* Define uma altura fixa */
    }
    .video-container {
        margin-top: 5px; /* Ajusta a margem superior para dispositivos móveis */
    }
}
</style>
