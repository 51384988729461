<template>
  <div class="project-card">
 <div class="projetos">  <h1>Projetos</h1>
    <img class="quadrinho" src="@/assets/projeto.png" alt="lampada"></div> 

    <p>Abaixo estão alguns dos projetos que fiz, incluindo um projeto real que desenvolvi para uma empresa de forma voluntária e sem fins lucrativos. Espero que goste do meu repositório.</p>
    <div class="project-image" @mouseover="showOverlay" @mouseleave="hideOverlay">
      <img class="quadrinho" src="@/assets/kiconsertos2.0.jpg" alt="kicomsertos">
      <div class="overlay" :class="{ show: overlayVisible }">
        <h3>{{ title }}</h3>
        <p>{{ description }}</p>

        <a href="https://kiconsertos20.com/" target="_blank" class="ancora">Site Kiconsertos2.0</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'siteseapi',
  props: {
    image: String,
    title: String,
    description: String
  },
  data() {
    return {
      overlayVisible: false
    };
  },
  methods: {
    showOverlay() {
      this.overlayVisible = true;
    },
    hideOverlay() {
      this.overlayVisible = false;
    }
  }
};
</script>

<style scoped>

.project-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 100px;
  background-color: black;
  border: solid #58cbf2;
  font-family: 'Roboto', sans-serif;
  color: white;
  gap: 20px;

}
.project-image {
  position: relative;
  
}
.project-image img {
  width: 350px;
  height: auto;
  border-radius: 10px;
  
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background: rgba(88, 203, 242, 0.8);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: height 0.5s;
  border-radius: 10px;
}
.overlay.show {
  height: 100%;
}
.overlay h3, .overlay p {
  margin: 5px 0;
}
.ancora {
  display: block;
  margin: 10px 0;
  color: white;
  text-decoration: none;
  background-color: black;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s;
}
.ancora:hover {
  background-color: black;
}
h1{
  color: white;
}
.projetos{
  display: flex;
  flex-direction: row;
  align-items: center;
}
p{
  margin-left: 20px;
}
@media screen and (max-width: 768px){


  .project-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px;
  background-color: black;
  border: solid #58cbf2;
  font-family: 'Roboto', sans-serif;
  color:white;
  gap: 20px;

}
.project-image img {
  width: 200px;
  height: auto;
  border-radius: 10px;
  
}
h1{
  font-size: 20px;
  margin-left: 50px;
}

}
</style>
