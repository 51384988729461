<template>
  <div>
    <header :class="{ 'header-fixo': headerFixo }">
      <img class="imagemgui" :src="require('@/assets/g.png')" alt="Guilherme Mendes">
      <div class="routers">
        <a @click="scrollToSection('inicio')" class="nav-link">Início</a>
        <a @click="scrollToSection('historia')" class="nav-link">História</a>
        <a @click="scrollToSection('projetos')" class="nav-link">Projetos</a>
      </div>
      <div class="ancoras">
        <a href="https://www.linkedin.com/in/guilherme-mendes-50aba6259/" target="_blank" aria-label="LinkedIn">
          <img class="icone" src="@/assets/linkedin.jpg" alt="LinkedIn" @mouseover="moveIcon('icone')" @mouseout="resetIcon('icone')" @click="resetIcon('icone')">
        </a>
        <a href="https://github.com/guilhermeguigg" target="_blank" aria-label="GitHub">
          <img class="icone2" src="@/assets/github.png" alt="GitHub" @mouseover="moveIcon('icone2')" @mouseout="resetIcon('icone2')" @click="resetIcon('icone2')">
        </a>
        <a href="https://wa.me/5521995711025" target="_blank" aria-label="WhatsApp">
          <img class="icone3" src="@/assets/wpp.png" alt="WhatsApp" @mouseover="moveIcon('icone3')" @mouseout="resetIcon('icone3')" @click="resetIcon('icone3')">
        </a>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: 'cabecalho',
  data() {
    return {
      headerFixo: false
    };
  },
  mounted() {
    window.addEventListener('scroll', this.verificarRolagem);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.verificarRolagem);
  },
  methods: {
    verificarRolagem() {
      this.headerFixo = window.scrollY > 0;
    },
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    },
    moveIcon(iconClass) {
      const icon = document.querySelector(`.${iconClass}`);
      icon.style.transform = 'translateY(-10px)';
    },
    resetIcon(iconClass) {
      setTimeout(() => {
        const icon = document.querySelector(`.${iconClass}`);
        icon.style.transform = 'translateY(0)';
      }, 300);
    }
  }
};
</script>

<style>
body {
  margin: 0;
  background: url('@/assets/fundo.png') no-repeat center center fixed;
  background-size: cover; /* Adiciona esta linha */
  background-attachment: fixed; /* Garante que a imagem fique fixa ao rolar a página */
}

header {
  background-color: transparent;
  color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  width: 100%;
}

/* Adicione o estilo para o header fixo */
.header-fixo {
  background-color: black;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000; /* Certifique-se de que o header esteja acima de outros elementos */
}

.imagemgui {
  width: 40px;
}

.routers {
  display: flex;
  gap: 15px;
  font-family: 'Roboto', sans-serif;
}

.nav-link {
  cursor: pointer; /* Adiciona o cursor de ponteiro */
}

.ancoras {
  display: flex;
  gap: 10px; /* Reduza o gap entre as âncoras */
  margin-right: 20px;
  align-items: center;
}

a:hover {
  background-color: #58cbf2;
}

.icone2,
.icone3 {
  width: 40px; /* Ajuste o tamanho dos ícones */
  transition: transform 0.3s;
}

.icone:hover,
.icone2:hover,
.icone3:hover {
  transform: translateY(-10px);
}

.icone2 {
  width: 30px; /* Ajuste o tamanho dos ícones */
}

.icone3 {
  width: 40px; /* Ajuste o tamanho dos ícones */
}

.icone {
  width: 50px;
  transition: transform 0.3s;
}

@media screen and (max-width: 768px) {
  body {
    margin: 0;
    background: url('@/assets/fundomobile.png') no-repeat center center fixed;
    background-size: cover; /* Adiciona esta linha */
    background-attachment: fixed; /* Garante que a imagem fique fixa ao rolar a página */
  }

  .routers {
    display: none; /* Ocultar os routers em telas menores */
  }

  .ancoras {
    gap: 5px; /* Reduza o gap entre as âncoras para telas menores */
    margin-right: 10px;
  }

  .icone1 {
    width: 30px;
  }

  .icone {
    width: 50px;
  }

  .icone3 {
    width: 40px;
  }

  .header-fixo {
    background-color: black;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }

  .imagemgui {
    width: 35px;
    margin-right: 100px;
  }

  header {
    background-color: transparent;
    color: white;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    width: 100%;
  }
}
</style>
